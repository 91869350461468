class ApiCms {
    user = ''
    pass = ''
    jsession = ''

    async peticion(url,params){
        try{
            const d = await fetch(url + new URLSearchParams(params))
            const j = await d.json()
            return j
            /*if(j.result===0){
                return j
            }else{
                console.log('CMS responde error en peticion',j.result,j.message)
                throw new Error('CMS Error en peticion, result '+j.result+', mensaje '+j.message)
            }*/
        }catch (e) {
            console.log('error peticion',e)
            throw e
        }
    }
    async login(){
        try{
            const p = await this.peticion('http://cms.cvx-r.cl/StandardApiAction_login.action?',{
                account:this.user,
                password:this.pass
            })
            if(p.result===0){
                this.jsession = p.jsession
                console.log('Obtuvimos jsession',this.jsession)
            }
            return p
        }catch (e) {
            console.log('error login',e)
            throw e
        }
    }
    async consultaVehiculosDvr(){
        try{
            if(this.jsession==='')
                await this.login()
            return this.peticion('http://cms.cvx-r.cl/StandardApiAction_queryUserVehicle.action?',{
                jsession: this.jsession
            })

        }catch (e) {
            console.log('error consultaVehiculosDvr',e)
            throw e
        }
    }

    async consultaEstadoDvr(nm=''){
        try{
            if(this.jsession==='')
                await this.login()
            const params={jsession:this.jsession}
            if(nm!='')
                params['vehiIdno']=nm
            return this.peticion('http://cms.cvx-r.cl/StandardApiAction_getDeviceStatus.action?',params)

        }catch (e) {
            console.log('error consultaEstadoDvr',e)
            throw e
        }
    }
    getUrlVideoVivo(nm) {

        return `http://cms.cvx-r.cl/808gps/open/player/video.html?lang=en&vehiIdno=${nm}&jsession=${this.jsession}`

    }
    async consultaSiHayVideo(DevIDNO,LOC,CHN,YEAR,MON,DAY,RECTYPE,FILEATTR,BEG,END,ARM1,ARM2,RES,STREAM,STORE){
        try{
            if(this.jsession==='')
                await this.login()
            return this.peticion('http://cms.cvx-r.cl/StandardApiAction_getVideoFileInfo.action?',{
                jsession: this.jsession,
                DevIDNO: DevIDNO,
                LOC: LOC,
                CHN: CHN,
                YEAR: YEAR,
                MON: MON,
                DAY: DAY,
                RECTYPE: RECTYPE,
                FILEATTR: FILEATTR,
                BEG: BEG,
                END: END,
                ARM1: ARM1,
                ARM2: ARM2,
                RES: RES,
                STREAM: STREAM,
                STORE: STORE
            })

        }catch (e) {
            console.log('error consultaEstadoDvr',e)
            throw e
        }
    }
    async ingresaTareaDescarga(did,fbtm,fetm,sbtm,setm,lab,fph,vtp,len,chn,dtp){
        if(this.jsession==='')
            await this.login()
        return this.peticion('http://cms.cvx-r.cl/StandardApiAction_addDownloadTask.action?',{
            jsession: this.jsession,
            did: did,
            fbtm: fbtm,
            fetm:fetm,
            sbtm: sbtm,
            setm: setm,
            lab: lab,
            fph: fph,
            vtp: vtp,
            len: len,
            chn: chn,
            dtp: dtp
        })
    }
    async consultaTareaDescarga(devIdno,begintime,endtime){
        if(this.jsession==='')
            await this.login()
        return this.peticion('http://cms.cvx-r.cl/StandardApiAction_downloadTasklist.action?',{
            jsession: this.jsession,
            devIdno: devIdno,
            begintime: begintime,
            endtime:endtime
        })
    }
    async tareaDescargaVideo(url){
        //si llega una url, hay que setear la variable jsession
        //"http://170.239.85.174:80/StandardApiAction_addDownloadTask.action?jsession=&did=001509&fbtm=2023-06-01 09:07:33&fetm=2023-06-01 09:31:59&sbtm=2023-06-01 09:07:33&setm=2023-06-01 09:31:59&lab=&fph=rec/a4/fly00572.ifv-offset0&vtp=0&len=267235720&chn=0&dtp=1"
        try{
            const newurl = url.replace('jsession=&','jsession='+this.jsession+'&')
            return this.peticion(newurl,{})
        }catch (e) {
            console.log('error consultaEstadoDvr',e)
            throw e
        }
    }

    getLinkDescargaDirecta(url){
        //"http://170.239.85.174:6604/3/5?DownType=3&jsession=&DevIDNO=1008&FILELOC=1&FLENGTH=16136694&FOFFSET=0&MTYPE=1&FPATH=/0000_00950001_6_1685692800_1685692811_0_16136694.avi&SAVENAME="
        const newurl = url.replace('jsession=&','jsession='+this.jsession+'&')
        const t = newurl.split('&')
        for(let ele of t){
            //console.log(ele)
            if(ele.startsWith('FPATH=')){
                const x = ele.slice(6).split('/')
                const fn = x[x.length-1]
                //console.log(x,fn)
                return newurl.replace('&SAVENAME=','&SAVENAME='+fn)
            }
        }
        return newurl
    }

}
export default ApiCms