import { useState, useEffect, useRef } from 'react';
import Autocomplete from "./Autocomplete";
import moment from 'moment';
import 'moment/locale/es';
import Datetime from 'react-datetime'
import ApiCms from "./ApiCms";
import Select, { StylesConfig } from 'react-select'
import {
    Button,
    Col,
    Form,
    List,
    Label,
    Row,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input, Table, Spinner, FormGroup
} from "reactstrap";
import chroma from 'chroma-js';
import "react-datetime/css/react-datetime.css";
import './Cms2.css'
import {ColourOption} from './datos.js'
const Cms= ()=>{
    let isInitFinished = false
    let swfobject;
    //const ref = useRef(null)
    const [Vehiculos,SetVehiculos] = useState([])
    const [Clientes,SetClientes] = useState([])
    const [VehiculoDvr,SetVehiculoDvr] = useState(null)
    const [DvrOnline,SetDvrOnline]=useState([])
    //const [jsession,SetJsession]=useState('')
    const [HayError,SetHayError]=useState(false)
    const [StrError,SetStrError]=useState('')
    const [HayErrorLogin,SetHayErrorLogin]=useState(false)
    const [StrErrorLogin,SetStrErrorLogin]=useState('')
    const [StrEstado,SetStrEstado]=useState(null)
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [modal3, setModal3] = useState(false)
    const [modal4, setModal4] = useState(false)
    const [modalvideo, setModalVideo] = useState(false)
    const [FechaDesde,SetFechaDesde] = useState(null)
    const [HoraDesde,SetHoraDesde] = useState(null)
    const [HoraHasta,SetHoraHasta] = useState(null)
    const [ObjApiCms,SetObjApiCms] = useState(null)
    const [DvrID,SetDvrID] = useState("")
    const [SelectCanales,SetSelectCanales] = useState(null)
    const [Canal,SetCanal] = useState(-1)
    const [TablaResultadoBusqueda,SetTablaResultadoBusqueda] = useState(null)
    const [VideosTarea,SetVideosTarea]=useState([])
    //const [SelectClientes,SetSelectClientes]=useState(null)
    //const [SelectVehiculos,SetSelectVehiculos]=useState(null)
    const [IdCliente,SetIdCliente] =useState(-1)
    const [PlayBackURL,SetPlayBackURL]=useState('')
    const [DuracionTarea,SetDuracionTarea]=useState(6)
    const [NotificarFinTarea,SetNotificarFinTarea]=useState(false)
    const [EmailNotificacion,SetEmailNotificacion]=useState('')
    const [EstamosIn,SetEstamosIn]=useState(false)
    const onDismiss = () => SetHayError(false);
    const onDismissLogin = () => SetHayErrorLogin(false);
    const toggle = () => setModal(!modal)
    const toggle2 = () => setModal2(!modal2)
    const toggle3 = () => setModal3(!modal3)
    const toggle4 = () =>setModal4(!modal4)
    const togglevideo=()=>setModalVideo(!modalvideo)
    useEffect(()=>{
        let lala
        lala=new ApiCms()
        console.log('Creamos instancia de ApiCms')
        SetObjApiCms(lala)
        const interval = setInterval(async ()=>{
            console.log("repeticion")
            if(lala.jsession!=''){
                const e = await lala.consultaEstadoDvr()
                if(e.result===5){
                    lala.jsession=''
                    SetEstamosIn(false)
                }else if(e.result===0)
                    SetDvrOnline(e.status)
                else
                    console.log(e)
            }else{
                console.log('Aun no hay login')
            }

        }, 30000)
        return () => clearInterval(interval)
    },[])
    /*useEffect(  ()=>{

        let lala
        const consultaInicial=async()=>{
            lala = new ApiCms()
            console.log("Inicio")
            const d = await lala.consultaVehiculosDvr()
            SetVehiculos(d.vehicles)
            SetClientes(d.companys)
            console.log("Seguimos")
            const e = await lala.consultaEstadoDvr()
            SetDvrOnline(e.status)
            SetObjApiCms(lala)
            console.log("Terminamos")
        }
        consultaInicial().catch(e=>{
            console.error(e)
        })
        const interval = setInterval(async ()=>{
            console.log("repeticion")
            const e = await lala.consultaEstadoDvr()
            if(e.result===5)
                await lala.login()
            else
                SetDvrOnline(e.status)
        }, 30000);

        return () => clearInterval(interval);

    }, [])*/


    const ManejaCambioCanal = e =>{
        console.log(e.target.value)
        SetCanal(e.target.value)
    }
    const getHMS = (segs)=>{
        const h=Math.trunc(segs/3600)
        const t = segs % 3600
        const m = Math.trunc(t/60)
        const s = t % 60
        const c = `${String(h).padStart(2,'0')}:${String(m).padStart(2,'0')}:${String(s).padStart(2,'0')}`
        return c
    }
    const BuscarTareas = async () =>{
        try{
            SetHayError(false)
            toggle2()
            const inicio=`${FechaDesde.format('YYYY-MM-DD')}`
            const fin=`${HoraDesde.format('YYYY-MM-DD')}`
            //const d= await ObjApiCms.consultaTareaDescarga(DvrID,inicio,fin)
            //console.log(d)
            const d = await fetch('api/listatareas?'+new URLSearchParams({
                dvrid: DvrID,
                fechadesde: inicio,
                fechahasta: fin
            }))
            if(!d.ok){
                SetStrError(d.statusText)
                SetHayError(true)
                return
            }
            const e = await d.json()
            //console.log(e)
            const filas=e.map((obj,ind)=>{
                const canal=(obj.canal===-1)?'TODOS':obj.canal+1
                const rango_busqueda=`${obj.fecha_busqueda} de ${obj.hora_inicio} a ${obj.hora_fin}`
                //const estadotarea=(obj.completada)?'COMPLETADA':'PENDIENTE'
                let estadotarea='PENDIENTE'
                if(obj.completada===1)
                    estadotarea='COMPLETADA'
                else if(obj.completada===2)
                    estadotarea='CANCELADA'
                return(
                    <tr key={'rb'+ind}>
                        <td>{obj.dvrid}</td>
                        <td>{canal}</td>
                        <td>{rango_busqueda}</td>
                        <td>{obj.fecha_creacion}</td>
                        <td>{obj.fecha_inicio}</td>
                        <td>{obj.fecha_ultimo_estado}</td>
                        <td>{obj.descripcion}</td>
                        <td>{estadotarea}</td>
                        <td>{(obj.ficheros_disponibles)?<Button className={"btn btn-info"} type={"button"} onClick={async () => verVideosTarea(obj.id)}>Ver videos</Button>:<></>}</td>
                        <td>{(obj.completada===0)?<Button className={"btn btn-warning"} type={"button"} onClick={async () => cancelarTarea(obj.id)}>Cancelar tarea</Button>:<></>}</td>
                    </tr>
                )
            })
            SetTablaResultadoBusqueda(
                <Table>
                    <thead>
                    <tr key={'rb-1'}><th>DVRID</th><th>CANAL</th><th>RANGO</th><th>F. CREACION</th><th>F. INICIO</th><th>F. ULTIMO ESTADO</th><th>ULTIMO ERROR</th><th>ESTADO</th><th></th><th></th></tr>
                    </thead>
                    <tbody>
                    {filas}
                    </tbody>
                </Table>)
        }catch (x) {
            console.log('lslslsls',Object.keys(x))
        }
    }
    const verVideosTarea = async (tarea) =>{
        try{
            const d = await fetch('api/listavideos/'+tarea)
            const e = await d.json()
            //console.log(e)
            SetVideosTarea(e)
            toggle4()
        }catch (e) {
            console.log('error pidiendo lista videos',e)
        }
    }
    const cancelarTarea = async (tarea)=>{
        try{
            const d = await fetch('api/cancelatarea/'+tarea)
            const e = await d.json()
            //console.log(e)
            SetVideosTarea(e)
            //toggle4()
        }catch (e) {
            console.log('error cancelando tarea',e)
        }
    }
    const IngresarTarea= async (email,notificar,duracion) =>{
        try{
            //const inicio = `${FechaDesde.format('YYYY-MM-DD')} ${HoraDesde.format('hh:mm:ss')}`
            //const hasta = `${FechaDesde.format('YYYY-MM-DD')} ${HoraHasta.format('hh:mm:ss')}`
            //const d = await ObjApiCms.ingresaTareaDescarga(DvrID,inicio,hasta,inicio,hasta,'prueba','/video.avi',0,0,Canal,2)
            //console.log(FechaDesde)
            //console.log(HoraDesde)
            //console.log(HoraHasta)
            const datos ={
                dvrid: DvrID,
                dvrdesc: VehiculoDvr.nm,
                canal: Canal,
                fecha_busqueda: FechaDesde.format('YYYY-MM-DD'),
                hora_inicio: HoraDesde.format('HH:mm:ss'),
                hora_fin: HoraHasta.format('HH:mm:ss'),
                tiempo_limite: duracion,
                notificar_resultado: notificar,
                correos: email
            }
            console.log(datos)
            console.log(JSON.stringify(datos))
            await ingresaTareaServidorCVX(datos)
        }catch (e) {
            console.log(e)
        }
        toggle3()
    }
    const ingresaTareaServidorCVX = async (datos)=>{
        try{
            const mihead = new Headers()
            mihead.append('Content-Type', 'application/json; charset=UTF-8')
            const d = await fetch('api/listatareas',{
                method:"POST",
                body:JSON.stringify(datos),
                headers:mihead,

            })
            if(!d.ok){
                SetStrError(d.statusText)
                SetHayError(true)
                return
            }
            const x = await d.json()
            return x
        }catch (e) {
            console.log("Error enviando tarea a server", e)
            throw e
        }
    }
    const BuscarVideos = async ()=>{
        try{
            //console.log(FechaDesde)
            //console.log(HoraDesde)
            //console.log(HoraHasta)
            toggle()

            const d = await ObjApiCms.consultaSiHayVideo(
                DvrID,1,Canal,FechaDesde.year(),FechaDesde.month()+1,FechaDesde.date(),0,2,
                HoraDesde.hour()*3600+HoraDesde.minute()*60,HoraHasta.hour()*3600+HoraHasta.minute()*60,0,0,0,0,0)
            console.log(d)
            if(d.result===0){
                if("files" in d){
                    const filas=d.files.map((obj,ind)=>{
                        const fecha=`${String(obj.day).padStart(2,'0')}/${String(obj.mon).padStart(2,'0')}/${obj.year}`
                        //const fechac = `20${obj.year}-${String(obj.mon).padStart(2,'0')}-${String(obj.day).padStart(2,'0')}`
                        const desde = getHMS(obj.beg)
                        const hasta = getHMS(obj.end)
                        //const hastac = getHMS(obj.end+1)//para ver si lo pillamaso
                        const tamano = Math.round(obj.len/(1024*1024))
                        return <tr key={'tbv'+ind}><td>{obj.chn+1}</td>
                            <td>{fecha}</td>
                            <td>{desde} - {hasta}</td>
                            <td>{tamano}M</td>
                            <td><Button className={"btn btn-primary"} type={"button"} onClick={()=>ReproducirVideo(obj.PlaybackUrl)}>PLAY</Button></td>


                        </tr>
                    })
                    const strcanal=(Canal===-1)?'todos los canales':'canal '+(parseInt(Canal)+1)
                    SetTablaResultadoBusqueda(
                        <div>
                            <p>Resultado búsqueda día {FechaDesde.format("DD/MM/YYYY")} entre {HoraDesde.format("HH:mm")} y {HoraHasta.format("HH:mm")} {strcanal}</p>
                            <Table>
                                <thead>
                                    <tr key={'tbv-1'}><th>Canal</th><th>Fecha</th><th>Lapso</th><th>Tamaño</th><th></th></tr>
                                </thead>
                                <tbody>
                                    {filas}
                                </tbody>
                            </Table>
                        </div>
                    )
                }else{
                    SetTablaResultadoBusqueda(null)
                    SetHayError(true)
                    SetStrError("No hay videos para el rango indicado")
                }
            }
        }catch (e) {
            console.log("Error en buscar videos",e)
            SetTablaResultadoBusqueda(null)
            SetHayError(true)
            SetStrError("Error consultando videos")
        }
    }
    const AbreModalBuscarVideo = () =>{
        //console.log("se supone que limpiamos")
        //SetHoraHasta(null)
        //SetHoraDesde(null)
        //SetFechaDesde(null)
        toggle()
    }
    const AbreModalIngresarTarea = () =>{
        toggle3()
    }
    const AbreModalBuscarTarea = () =>{
        toggle2()
    }
    const ReproducirVideo = (url)=>{
        //initPlayer()
        //startPlayback(url)
        SetPlayBackURL(url)
        togglevideo()
    }
    const PlayVideo=()=>{
        initPlayer()
        startPlayback(PlayBackURL)
    }
    const ProgramarDescargaVideo = async (dvrid,nm,canal,fdesde,hdesde,hhasta) =>{
        //const d = await ObjApiCms.tareaDescargaVideo(url)
        //console.log(d)
        const datos ={
            dvrid: dvrid,
            dvrdesc: nm,
            canal: canal,
            fecha_busqueda: fdesde,
            hora_inicio: hdesde,
            hora_fin: hhasta
        }
        return await ingresaTareaServidorCVX(datos)
    }

    const initPlayer = ()=>{
        isInitFinished = false;
        const options = {
            domId: "cmsv6flash",
            isVodMode:true,
            width: 400,
            height: 300,
            //lang:  "en"
        }
        console.log('uhhhh?')
        swfobject = new window.Cmsv6Player(options);
        console.log('aqui?')
        initFlash();
    }
    const initFlash = ()=>{
        if (typeof swfobject == "undefined" ||
            typeof swfobject.setWindowNum == "undefined") {
            setTimeout(initFlash, 50);
        } else {
            swfobject.setWindowNum(36);
            swfobject.setWindowNum(1);
            // Set server information

            swfobject.setServerInfo("cms.cvx-r.cl", 6605);
            isInitFinished = true;
        }
    }
    const startPlayback = (url) => {
        //Stop Playback
        swfobject.getObjectById("cmsv6flash").stopVideo(0);
        //Start Playback
        const ret = swfobject.getObjectById("cmsv6flash").startVod(0, url);
        console.log(ret)
    }
//Stop Playback
    const stopPlayback = () => {
        swfobject.getObjectById("cmsv6flash").stopVideo(0);
    }
    const alguienTipea=()=>{
        //console.log("Alguien tipea")
        SetStrError('')
        SetHayError(false)
        SetStrEstado(null)
        SetFechaDesde(null)
        SetHoraDesde(null)
        SetHoraHasta(null)
        SetDvrID(0)
        SetSelectCanales(null)
        SetCanal(-1)
        SetTablaResultadoBusqueda(null)
        SetVideosTarea([])
        SetDuracionTarea(6)
        SetNotificarFinTarea(false)
        SetEmailNotificacion('')
        //ref.current.setAttribute('style',{display: "none"})
    }
    const cambioCliente = (e)=>{
        console.log('seleccionaron',e)
        alguienTipea()
        //SetSelectVehiculos(llenaSelectVehiculos(Vehiculos,e.target.value))
        SetIdCliente(parseInt(e.value))
    }
    const dot = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });
    const colourStyles: StylesConfig<ColourOption> = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                },
            };
        },
        input: (styles) => ({ ...styles, ...dot() }),
        placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
    const SelectClientes = Clientes.map(cliente=>{
        return {value: cliente.id,label:cliente.nm,color: '#00B8D9'}
        {/*return(
            <option key={'c'+cliente.id} value={cliente.id} >{cliente.nm}</option>
        )*/}
    })
    const SelectVehiculos= (props)=>{

        const listavehiculos=[]
        for(let vehiculo of Vehiculos){
            if(props.IdCliente===-1 || props.IdCliente===vehiculo.pid){
                const online=DvrOnline.find(equipo=>{
                    //console.log(equipo.id,vehiculo.dl[0].id)
                    return equipo.id===vehiculo.dl[0].id
                })
                let color='#b8c9c9'
                //console.log(online,DvrOnline[0])
                if(online!=undefined){
                    //console.log(online)
                    if(online.ol===1){
                        color='#04f80f'
                    }
                    else
                        color='#c71902'
                }
                listavehiculos.push({value: vehiculo.id, label: vehiculo.nm,color: color})
            }
        }
        return(
            <Select name={"select-vehiculos"} options={listavehiculos} onChange={consultaDetalle} isSearchable={true} styles={colourStyles} placeholder={"Seleccione dvr"}/>
        )
    }
    const consultaDetalle = d => {
        //d.preventDefault()
        alguienTipea()
        const idsel=parseInt(d.value)
        if(idsel<0){
            return
        }
        const veh=Vehiculos.find(veh => veh.id===idsel)
        console.log(d.value ,veh)
        SetDvrID(veh.dl[0].id)
        SetVehiculoDvr(veh)
        const optionselect = veh.dl[0].cn.split(',').map((ch,ind)=>{
            return <option key={ch} value={ind}>{ch}</option>
        })
        SetCanal(-1)
        optionselect.unshift(<option key={'CH-1'} value={-1}>Todos los canales</option>)
        SetSelectCanales(<Input type={"select"} id={"select-canal"} name={"select-canal"} onChange={ManejaCambioCanal}>{optionselect}</Input>)
    }
    const DetalleDVR=(props)=>{
        const online=DvrOnline.find(equipo=>{
            //console.log(equipo.id,vehiculo.dl[0].id)
            return equipo.id===props.dvrid
        })
        if(online!=undefined){
            const veh=VehiculoDvr
            let stronline='OFFLINE'
            let red='NO'
            if(online.net===1)
                red='4G'
            else if(online.net===2)
                red='WIFI'
            if(online.ol)
                stronline='ONLINE'
            return(
                <List type="unstyled">
                    <li>{veh.nm}</li>
                    <li>DvrID {veh.dl[0].id}</li>
                    <li>Canales {veh.dl[0].cn}</li>
                    <li>Último reporte GPS {online.gt}</li>
                    <li>Tipo Conexión {online.net}</li>
                    <li><a target="_blank" href={`http://maps.google.com/?q=${online.ps}`}>Última Ubicación</a></li>
                    {online.ol?<li><a target="_blank" href={ObjApiCms.getUrlVideoVivo(veh.nm)}>VIDEO EN LINEA</a></li>:<li>OFFLINE</li>}
                    <li>{online.ol?<Button color={"info"} onClick={AbreModalBuscarVideo}>Buscar video</Button>:<></>}<Button color={"info"} onClick={AbreModalIngresarTarea}>Ingresar Tarea</Button><Button color={"info"} onClick={AbreModalBuscarTarea}>Consultar Tareas</Button></li>
                </List>
            )
        }
        return(
            <></>
        )
    }
    const verificaLogin= async (e)=>{
        e.preventDefault()
        const {usuario, contrasena} = document.forms[0]
        console.log(usuario.value,contrasena.value)
        ObjApiCms.user=usuario.value
        ObjApiCms.pass=contrasena.value
        const res=await ObjApiCms.login()
        if(res.result===0){
            SetEstamosIn(true)
            const d = await ObjApiCms.consultaVehiculosDvr()
            SetVehiculos(d.vehicles)
            SetClientes(d.companys)
            console.log("Seguimos")
            const x = await ObjApiCms.consultaEstadoDvr()
            SetDvrOnline(x.status)
        }else{
            SetEstamosIn(false)
            console.log(res)
            SetStrErrorLogin(res.message)
            SetHayErrorLogin(true)
        }

    }
    const formulariologin=(
        <>
            <Alert color="danger" isOpen={HayErrorLogin} toggle={onDismissLogin}>
                {StrErrorLogin}
            </Alert>
        <Form onSubmit={verificaLogin}>
            <FormGroup row>
                <Label for={"usuario"} sm={4}>Usuario: </Label>
                <Col sm={8}><Input id={"usuario"} name={"usuario"} placeholder={"Ingrese nombre de usuario"}/></Col>
            </FormGroup>
            <FormGroup row>
                <Label for={"contrasena"} sm={4}>Contraseña: </Label>
                <Col sm={8}><Input id={"contrasena"} name={"contrasena"} placeholder={"Ingrese contraseña"} type={"password"}/></Col>
            </FormGroup>
            <FormGroup>
                <Button>
                    Enviar
                </Button>
            </FormGroup>
        </Form>
        </>
    )
    const modotrabajo=
        (
            <>
        <ModalReproductor isopen={modalvideo} toggle={togglevideo} reproduce={PlayVideo} detiene={stopPlayback}/>
    <Spinner color={"primary"} style={{height: '4rem',width: '4rem'}} hidden={true}></Spinner>
    <ModalListaVideos isopen={modal4} toggle={toggle4} datos={VideosTarea}/>
    <ModalConsulta isopen={modal} toggle={toggle} titulo={"Buscar video"} SetFechaDesde={SetFechaDesde} SetHoraDesde={SetHoraDesde} SetHoraHasta={SetHoraHasta} Tarea={BuscarVideos} select={SelectCanales}/>
    <ModalConsulta isopen={modal3} estarea={true} toggle={toggle3} titulo={"Ingresar Tarea Descarga"} SetFechaDesde={SetFechaDesde} SetHoraDesde={SetHoraDesde} SetHoraHasta={SetHoraHasta} Tarea={IngresarTarea} select={SelectCanales}/>
    <ModalConsulta isopen={modal2} solofdfh={true} toggle={toggle2} titulo={"Consultar Tareas"} SetFechaDesde={SetFechaDesde} SetHoraDesde={SetHoraDesde} SetHoraHasta={SetHoraHasta} Tarea={BuscarTareas} select={""}/>
    <Alert color="danger" isOpen={HayError} toggle={onDismiss}>
        {StrError}
    </Alert>
    {/*<Form onSubmit={consultaCms}>*/}
    <Row className="row-cols-lg-auto g-3 align-items-center">
        <Col>
            {/*<Label for="iddvr" className="visually-hidden">Ingrese ID:</Label>*/}
            {/*<Autocomplete suggestions={Vehiculos.map(item => item.nm)} aviso={alguienTipea}></Autocomplete>*/}
            {/*<Input id={"select-clientes"} name={"select-clientes"} type={"select"} onChange={cambioCliente}>
                            <option key={'c-1'} value={-1}>Seleccione cliente</option>
                            {SelectClientes}
                        </Input>*/}
            <Select name={"select-clientes"} options={SelectClientes} onChange={cambioCliente} styles={colourStyles} placeholder={"Seleccione cliente"}/>
        </Col>
        <Col>
            {/*<Input id={"select-vehiculos"} name={"select-vehiculos"} type={"select"} onChange={consultaCms}>
                            <option key={'v-1'} value={-1}>Seleccione vehículo</option>
                            {SelectVehiculos}
                        </Input>*/}
            <SelectVehiculos IdCliente={IdCliente}/>
        </Col>

    </Row>
    {/*}</Form>*/}
    <DetalleDVR dvrid={DvrID}/>
    {TablaResultadoBusqueda}
            </>
    )
    return(
    <>
            {EstamosIn?modotrabajo:formulariologin}
    </>

    )
}


const ModalListaVideos = (props) =>{
    const tdata = () =>{
        return props.datos.map((data,ind) =>{
            let estado
            if(data.estado===1)
                estado='INICIANDO'
            else if(data.estado===2)
                estado='EN PROCESO'
            else if(data.estado===3)
                estado='FALLIDA'
            else if(data.estado===4)
                estado='DISPONIBLE'
            return (
                <tr key={'mlv'+ind}>
                    <td>{data.canal+1}</td>
                    <td>{data.fecha}</td>
                    <td>{data.horadesde}</td>
                    <td>{data.horahasta}</td>
                    <td>{estado}</td>
                    <td>{data.estado===4?<a target={"_blank"} href={data.link_descarga}>DESCARGAR</a>:<></>}</td>
                </tr>
            )
        })
    }
    return (
        <Modal isOpen={props.isopen} toggle={props.toggle} size={"lg"} >
            <ModalHeader>Lista Videos Tarea</ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                    <tr key={'mlv-1'}><th>Canal</th><th>Fecha</th><th>Inicio</th><th>Fin</th><th>Estado</th><th></th></tr>
                    </thead>
                    <tbody>
                    {tdata()}
                    </tbody>

                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
//titulo={"Buscar video"} SetFechaDesde={SetFechaDesde} SetHoraDesde={SetHoraDesde} SetHoraHasta={SetHoraHasta} TareaBusqueda={BuscarVideos}
const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const ModalConsulta = (props) =>{
    const [controlemail,Setcontrolemail]=useState(false)
    const [duracion,Setduracion]=useState(6)
    const inputRef = useRef(null)
    //let controlemail=false
    //let duracion=6
    //Setcontrolemail(false)
    //Setduracion(6)
    const abrieron=()=>{
        console.log("Abrieron....")
        Setcontrolemail(false)
        Setduracion(6)
    }
    const cambionotifica=(e)=>{
        console.log('????',e.target.checked)
        /*if(e.target.value==='on'){
            Setcontrolemail(false)
        }else{
            Setcontrolemail(true)
        }*/
        Setcontrolemail(e.target.checked)
        //controlemail=e.target.checked
        //props.SetNotificar(e.target.checked)
    }

    const placeholder = {placeholder:'Ingresa hora desde'}
    if(props.solofdfh){
        placeholder.placeholder='Ingresa fecha hasta'
    }
    const SetDuracionTarea=(e)=>{
        console.log('duracion',e.target.value)
        Setduracion(parseInt(e.target.value))
        //duracion=parseInt(e.target.value)
    }
    const verifica=()=>{
        if(props.estarea){
            if(controlemail){

                if(!isEmail(inputRef.current.value)){
                    alert('Ingresa un email válido')
                }else{
                    //props.SetEmailNotificaion(inputRef.current.value)
                    console.log(inputRef.current.value,controlemail,duracion)
                    props.Tarea(inputRef.current.value,controlemail,duracion)
                }
            }else{
                console.log(inputRef.current.value,controlemail,duracion)
                props.Tarea(inputRef.current.value,controlemail,duracion)
            }
        }else{
            props.Tarea()
        }
    }
    return (
        <Modal isOpen={props.isopen} toggle={props.toggle} onOpened={abrieron}>
            <ModalHeader>{props.titulo}</ModalHeader>
            <ModalBody>
                <Row><Datetime dateFormat="YYYY-MM-DD" timeFormat={false} inputProps={{placeholder:'Ingresa fecha'}} onChange={props.SetFechaDesde} closeOnSelect={true}/></Row>
                {props.solofdfh?<Row><Datetime dateFormat={"YYYY-MM-DD"} timeFormat={false} inputProps={placeholder} onChange={props.SetHoraDesde} closeOnSelect={true}></Datetime></Row>:
                <Row><Datetime dateFormat={false} timeFormat={"HH:mm"} inputProps={placeholder} onChange={props.SetHoraDesde} closeOnSelect={true}></Datetime></Row>}
                {props.solofdfh?<></>:<Row><Datetime dateFormat={false} timeFormat={"HH:mm"} inputProps={{placeholder:'Ingresa hora hasta'}} onChange={props.SetHoraHasta} closeOnSelect={true}/></Row>}
                <Row>{props.select}</Row>
                {props.estarea?<Row><Label for={"select-duracion"}>Duración tarea en horas</Label><Input id={"select-duracion"} type={"select"} defaultValue={6} onChange={SetDuracionTarea}>
                    <option value={1}>1</option>
                    <option value={2}>3</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={12}>12</option>
                    <option value={24}>24</option>
                    <option value={48}>48</option>
                </Input></Row>:<></>}
                {props.estarea?<Row><FormGroup check><Input name={"habilita-notifica"} type={"checkbox"} onChange={cambionotifica}/>{' '}<Label check>Notificar resultado</Label></FormGroup></Row>:<></>}
                {props.estarea?<Row><Input innerRef={inputRef} disabled={!controlemail} type={"email"} name={"correos"} placeholder={"Ingrese correo"}/></Row>:<></>}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={verifica}>
                    Buscar
                </Button>{' '}
                <Button color="secondary" onClick={props.toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const ModalReproductor=(props)=>{
    return(
        <Modal isOpen={props.isopen} toggle={props.toggle} onOpened={props.reproduce}>
            <ModalHeader>
                Reproductor Video CMS
            </ModalHeader>
            <ModalBody>
                <div id="cmsv6flash">
                    <div id="Cmsv6H5Video-cmsv6flash-0">

                    </div>
                </div>
            </ModalBody>
            <ModalFooter>

                <Button color="secondary" onClick={props.toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
export default Cms