//import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ThemeSwitcher from "./ThemeSwitcher";
import Cms from './Cms2'

function App() {
  return (
      <div className="App min-vh-100 d-flex justify-content-center align-items-center">
        <div>
          <Cms />
        </div>
      </div>
  );
}

export default App;
